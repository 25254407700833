import React from "react";
import { NextArrow, CopySection } from "@molecules";
import loadable from "@loadable/component";
import { Container } from "@atoms";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));
const VideoPlayerInline = loadable(() =>
  import("@organisms/VideoPlayerInline")
);

const GrowingImageCopy = ({
  heading,
  subheading,
  copy,
  video,
  image,
  smallerSpacer,
}) => {
  return (
    <GrowingImage heading={heading} image={image} smallerSpacer={smallerSpacer}>
      <Container frame>
        <CopySection
          twoColumns
          subheading={subheading}
          copy={copy}
          className="text-white"
        />
        <NextArrow className="mt-14" />
        {video && typeof window !== "undefined" && (
          <div className="mx-auto mt-14 max-w-2xl text-white md:mt-24 md:px-6 lg:mt-32 lg:px-14">
            <VideoPlayerInline url={video} />
          </div>
        )}
      </Container>
    </GrowingImage>
  );
};

export default GrowingImageCopy;
